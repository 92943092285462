let utilsMixin = {
    methods: {
        isMobilePhone (text) {
            return /^1\d{10}$/.test(text);
        },
        isEmail (text) {
            return /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(text);
        },
        isCardNo (text) {
            return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(text);
        },
        isComplexPassword (text) {
            return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z]{8,20}$/.test(text);
        },
        isAccount: function isAccount(text) {
            return (/^[a-zA-Z0-9]{6,20}$/.test(text))
        }
    }
}
